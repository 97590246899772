






















































import { SfButton, SfSearchBar, SfLoader } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  watch,
  useRoute,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/composables/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useFacet } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SfLoader,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  // eslint-disable-next-line sonarjs/no-duplicate-string
  emits: ['set-is-open', 'set-search-results', 'set-search-word'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const searchLoader = ref(false);

    // TODO implement gemini search
    // const { getProductList } = useProduct();

    const {
      result: searchResult,
      search: productsSearch,
      // loading: productsLoading,
    } = useFacet('AppHeader:Products');

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('search-show');
        }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results', null);
        emit('set-search-word', '');
        if (document) {
          document.body.classList.remove('search-show');
          document.body.classList.remove('search-box');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          const bodyEle = document.querySelectorAll('body');
          bodyEle.length > 0 && bodyEle[0].classList.remove('search-box');
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      searchLoader.value = true;
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) {
        searchLoader.value = false;
        emit('set-search-results', '');
        emit('set-search-word', '');
      }

      await productsSearch({
        itemsPerPage: 12,
        term: term.value,
        customQuery: {
          products: 'productListCustom',
        },
      });
      searchLoader.value = false;
      emit('set-search-results', searchResult.value?.data?.items);
      emit('set-search-word', term.value);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      let inputValue = searchTerm.toLowerCase();
      if (inputValue !== '') {
        router.push(app.localeRoute(`/search-result/?keyword=${inputValue}`));
      }
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      // debouncedHandleSearch.cancel();
      // rawHandleSearch(searchTerm);
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      searchLoader,
    };
  },
});
